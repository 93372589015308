import { action, makeAutoObservable, runInAction } from 'mobx';
import { refreshTokenPair, signIn, signUp } from '../services/auth';
import {
    removeTokenPair,
    saveTokenAndOrganization,
    saveTokenPair,
} from '../apiCommandsExecutor/token';
import rootStore from './index';
import { Mode } from '../common/store';
import { SignUpParams, TokenPair } from '../services/auth/models';
import { getCurrent } from '../services/accounts';
import { AccountClientModel } from '../services/accounts/models';
import { Organization } from '../services/organizations/models';

class AuthStore {
    isAuthorized: boolean;
    addUserAuthStatus: boolean;
    accountChangeModal = false;
    currentUser?: AccountClientModel;
    confirmModal = false;
    currentConfirmData: {
        token: TokenPair;
        organization: Organization;
        login: string;
    };
    updatedTokenPair: TokenPair;

    constructor() {
        makeAutoObservable(this);
    }

    async getCurrentUser() {
        const response = await getCurrent();
        if (response.status === 401) {
            if (localStorage.token && localStorage.accounts) {
                const prevToken = localStorage.getItem('token');
                await this.refreshToken(
                    (JSON.parse(prevToken) as TokenPair).refreshToken,
                );
                const accounts = JSON.parse(localStorage.getItem('accounts'));
                const newAccs = accounts.map((i) =>
                    i.token.refreshToken ===
                    (JSON.parse(prevToken) as TokenPair).refreshToken
                        ? { ...i, token: this.updatedTokenPair }
                        : i,
                );
                await localStorage.setItem(
                    'accounts',
                    JSON.stringify([...newAccs]),
                );
                await this.getCurrentUser();
            } else {
                await this.logout();
            }
        } else if (response.status === 200) {
            runInAction(() => {
                this.isAuthorized = true;
                this.currentUser = {
                    ...response.data.account,
                };
            });
            rootStore.generalStore.init();
            if (this.currentUser.login === 'demo') {
                rootStore.generalStore.setMode(Mode.demo);
            }
        } else {
            await this.logout();
        }
    }

    async signIn(params: { login: string; password: string }) {
        const response = await signIn(params);
        if (!response) {
            runInAction(() => {
                this.isAuthorized = false;
            });
            return;
        }
        saveTokenPair(response.tokenPair);
        saveTokenAndOrganization(
            response.tokenPair,
            response.organization,
            response.account.login,
        );
        await this.getCurrentUser();
    }

    async addUserToChangeModal(params: { login: string; password: string }) {
        runInAction(() => {
            this.addUserAuthStatus = true;
        });
        const response = await signIn(params);
        if (!response) {
            runInAction(() => {
                this.addUserAuthStatus = false;
            });
            return;
        }
        saveTokenPair(response.tokenPair);
        saveTokenAndOrganization(
            response.tokenPair,
            response.organization,
            response.account.login,
        );
    }

    async refreshToken(refreshToken: string) {
        const response = await refreshTokenPair({
            refreshToken: refreshToken,
        });
        if (!response) {
            if (localStorage.accounts) {
                const accounts = JSON.parse(localStorage.getItem('accounts'));
                const newAccs = accounts.filter(
                    (i) => i.token.refreshToken !== refreshToken,
                );
                await localStorage.setItem(
                    'accounts',
                    JSON.stringify([...newAccs]),
                );
            }
            await this.logout();
        } else {
            saveTokenPair(response.tokenPair);
            this.updatedTokenPair = response.tokenPair;
        }
    }

    async signUp(params: SignUpParams) {
        const response = await signUp(params);
        if (!response) {
            runInAction(() => {
                this.isAuthorized = false;
            });
            return;
        }
        saveTokenPair(response.tokenPair);
        await this.getCurrentUser();
    }

    async logout() {
        removeTokenPair();
        runInAction(() => {
            this.isAuthorized = false;
            this.currentUser = undefined;
        });
    }

    @action
    openAccountChangeModal() {
        this.accountChangeModal = true;
    }

    @action
    closeAccountChangeModal() {
        this.accountChangeModal = false;
    }

    @action
    openConfirmModal(obj: {
        token: TokenPair;
        login: string;
        organization: Organization;
    }) {
        this.currentConfirmData = obj;
        this.confirmModal = true;
    }

    @action
    closeConfirmModal() {
        this.confirmModal = false;
    }
}

export default AuthStore;

import { action, makeAutoObservable, runInAction } from 'mobx';
import {
    createOrganizationBrunch,
    getManagementCompany,
    getOrganizationBrunches,
    getOrgBranchSettingsAndAvailableModulesById,
    removeOrgBranch,
    updateManagementCompany,
    updateOrganizationBrunch,
    updateOrgBranchSettingsAndAvailableModules,
} from '../services/organizations';
import {
    LocationInterface,
    OrganizationBranch,
    OrganizationBranchManagementCompanyPublic,
    OrgBranchAvailableModules,
    OrgBranchSettings,
} from '../services/organizations/models';
import {
    CreateOrganizationBranchRequest,
    OrganizationBranchesUpdateOrgBranchSettingsAndAvailableModulesRequest,
    UpdateOrganizationBranchRequest,
} from '../services/organizations/requestsResponses';
import {
    ObjectStorageGetUploadLinkRequest,
    ObjectStorageGetUploadLinkResponse,
} from '../services/objectStorage/requestsResponses';
import { getUploadLink } from '../services/objectStorage';
import {
    SystemObjectT,
    SystemObjectType,
} from '../services/systemObjects/types';
import {
    getSystemObjects,
    updateHouseSignUpConfig,
} from '../services/systemObjects';
import {
    createYooKassaProvider,
    getPaymentProviders,
    removePaymentProvider,
    updateYooKassaProvider,
} from '../services/paymentProviders';
import { ExecutionStatus } from '../apiCommandsExecutor/api';
import {
    PaymentProviderClient,
    PaymentProviderYooKassaClient,
    YooKassaProviderCreateModel,
    YooKassaProviderUpdateModel,
} from '../services/paymentProviders/models';
import {
    createTelephonyProvider,
    getTelephonyProviders,
    removeTelephonyProvider,
    updateTelephonyProvider,
} from '../services/telephony';
import {
    MangoOfficeTelephonyProviderClient,
    TelephonyProviderClient,
} from '../services/telephony/models';
import {
    TelephonyProviderCreateMangoOfficeTelephonyProviderRequest,
    TelephonyProviderUpdateMangoOfficeTelephonyProviderRequest,
} from '../services/telephony/requestsResponses';
import { getAccounts } from '../services/accounts';
import { AccountRole } from '../services/accounts/types';
import { AccountClientModel } from '../services/accounts/models';
import { isEqual } from 'lodash';
import { HouseResidentSignUpConfig } from '../services/systemObjects/models';

export enum OrganizationBranchesForms {
    CreateForm = 'CreateForm',
    EditForm = 'EditForm',
}

class OrganizationBranchesStore {
    formsState: { [key in OrganizationBranchesForms]: boolean } = {
        CreateForm: false,
        EditForm: false,
    };

    organizationBranches: OrganizationBranch[] = [];
    organizationBranchIdToEdit: number = null;
    organizationBranchIdToInfo: number = null;
    orgBranchEditModal = false;
    currentOrgBranchModules: OrgBranchAvailableModules;
    currentOrgBranchSettings: OrgBranchSettings;
    currentOrgBranchInfo: OrganizationBranchManagementCompanyPublic;
    cropFile: File;
    cropImageLink: string;
    cropModal = false;
    infoModal = false;
    logoFile: { fileId: string; uploadLink: string };
    photoFile: ObjectStorageGetUploadLinkResponse;
    cropPhotoForInfoLink: string;
    cropLogoForInfoLink: string;
    removeForm = false;
    orgBranchForRemove: OrganizationBranch;
    current: OrganizationBranch;
    loading = true;
    systemObjects: {
        [s: string]: SystemObjectT[] | null;
    } = {};
    id2SystemObjectMap: { [s: string]: SystemObjectT } = {};
    paymentProviders: PaymentProviderClient[] = [];
    editProviderModal = false;
    currentProvider: PaymentProviderClient;
    currentHouseId: number;
    removeProviderForm = false;
    providerForRemove: PaymentProviderClient;
    telephonyProviders: TelephonyProviderClient[] = [];
    editTelephonyModal = false;
    currentTelephony: TelephonyProviderClient;
    telephonyForRemove: TelephonyProviderClient;
    removeTelephonyForm = false;
    employeesList: AccountClientModel[] = [];
    branchIdForCreateTelephony: number;
    loadingChildren: {
        [s: string]: boolean;
    } = {};
    houseIdForSignUpConfig: number;
    signUpConfigModal = false;
    currentSignUpConfig: HouseResidentSignUpConfig;

    constructor() {
        makeAutoObservable(this);
    }

    init() {
        this.loadOrganizationBranches();
    }

    @action
    openForm(
        type: keyof typeof OrganizationBranchesForms,
        organizationId: number = null,
    ) {
        this.formsState[type] = true;
        this.organizationBranchIdToEdit = organizationId;
    }

    @action
    closeForm(type: keyof typeof OrganizationBranchesForms) {
        this.formsState[type] = false;
        this.organizationBranchIdToEdit = null;
    }

    @action
    openOrgBranchEditModal(orgBranchId: number) {
        this.organizationBranchIdToEdit = orgBranchId;
        this.orgBranchEditModal = true;
    }

    @action
    closeOrgBranchEditModal() {
        this.orgBranchEditModal = false;
    }

    @action
    openCropModal() {
        this.cropModal = true;
    }

    @action
    closeCropModal() {
        this.cropModal = false;
    }

    @action
    openInfoModal(orgBranchId: number) {
        this.organizationBranchIdToInfo = orgBranchId;
        this.infoModal = true;
    }

    @action
    closeInfoModal() {
        this.organizationBranchIdToInfo = undefined;
        this.infoModal = false;
    }

    @action
    setCropFile(file: File) {
        this.cropFile = file;
    }

    @action
    setCropImageSrc(src: string) {
        this.cropImageLink = src;
    }

    @action
    resetData() {
        this.organizationBranches = [];
    }

    @action
    openRemoveForm(branch: OrganizationBranch) {
        this.removeForm = true;
        this.orgBranchForRemove = branch;
    }

    @action
    closeRemoveForm() {
        this.removeForm = false;
        this.orgBranchForRemove = undefined;
    }

    async loadOrganizationBranches() {
        const res = await getOrganizationBrunches();
        runInAction(() => {
            this.organizationBranches = res?.organizationBranches || [];
        });
    }

    async getOrgBranchSettingsAndAvailableModulesById() {
        const res = await getOrgBranchSettingsAndAvailableModulesById({
            orgBranchId: this.organizationBranchIdToEdit,
        });
        this.currentOrgBranchModules = res.availableModules;
        this.currentOrgBranchSettings = res.settings;
    }

    async updateOrgBranchSettingsAndAvailableModules(
        params: OrganizationBranchesUpdateOrgBranchSettingsAndAvailableModulesRequest,
    ) {
        const res = await updateOrgBranchSettingsAndAvailableModules(params);
        return res === null;
    }

    async saveOrganizationBranch(params: CreateOrganizationBranchRequest) {
        const res = await createOrganizationBrunch(params);
        if (res) {
            this.closeForm(OrganizationBranchesForms.CreateForm);
            this.loadOrganizationBranches();
        }
    }

    async editOrganizationBranch(params: UpdateOrganizationBranchRequest) {
        const res = await updateOrganizationBrunch(params);
        if (res) {
            this.closeForm(OrganizationBranchesForms.EditForm);
            this.loadOrganizationBranches();
        }
    }

    async getOrgBranchManagementCompany() {
        const res = await getManagementCompany({
            orgBranchId: this.organizationBranchIdToInfo,
        });
        this.currentOrgBranchInfo = res.managementCompany;
    }

    async getPaymentProviders() {
        const getIds = this.organizationBranches.map((i) => i.id);
        const res = await getPaymentProviders({
            houseIds: [],
            organizationBranchIds: getIds,
        });
        if (res.executionStatus === ExecutionStatus.finished) {
            runInAction(() => {
                this.paymentProviders = res.providers;
            });
        }
    }

    async getTelephonyProviders() {
        const res = await getTelephonyProviders({});
        if (res.executionStatus === ExecutionStatus.finished) {
            runInAction(() => {
                this.telephonyProviders = res.telephonyProviders;
            });
        }
    }

    async createYooKassaProvider(providerModel: YooKassaProviderCreateModel) {
        const response = await createYooKassaProvider({
            paymentProvider: providerModel,
        });
        if (response.executionStatus !== ExecutionStatus.finished) {
            return { isError: true, errDescription: response.message };
        } else {
            return { isError: false };
        }
    }

    async updateYooKassaProvider(providerModel: YooKassaProviderUpdateModel) {
        const response = await updateYooKassaProvider({
            paymentProviderUpdated: providerModel,
        });
        if (response.executionStatus !== ExecutionStatus.finished) {
            return { isError: true, errDescription: response.message };
        } else {
            return { isError: false };
        }
    }

    async removePaymentProvider() {
        const response = await removePaymentProvider({
            paymentProviderId: (
                this.providerForRemove as PaymentProviderYooKassaClient
            ).id,
        });
        if (response.executionStatus !== ExecutionStatus.finished) {
            return { isError: true, errDescription: response.message };
        } else {
            return { isError: false };
        }
    }

    async createMangoOfficeTelephony(
        params: TelephonyProviderCreateMangoOfficeTelephonyProviderRequest,
    ) {
        const response = await createTelephonyProvider(params);
        if (response.executionStatus !== ExecutionStatus.finished) {
            return { isError: true, errDescription: response.message };
        } else {
            return { isError: false };
        }
    }

    async updateMangoOfficeTelephony(
        params: TelephonyProviderUpdateMangoOfficeTelephonyProviderRequest,
    ) {
        const currentMango = this
            .currentTelephony as MangoOfficeTelephonyProviderClient;

        const currentCreds = {
            vpbxApiSalt: currentMango.vpbxApiSalt,
            vpbxApiKey: currentMango.vpbxApiKey,
            apiUri: 'https://app.mango-office.ru/vpbx',
        };
        const response = await updateTelephonyProvider({
            extensionToEmployeeAccountIdMap:
                params.extensionToEmployeeAccountIdMap,
            lineNumber: params.lineNumber,
            mangoOfficeProviderId: params.mangoOfficeProviderId,
            title: params.title,
            credentials: isEqual(params.credentials, currentCreds)
                ? null
                : params.credentials,
        });
        if (response.executionStatus !== ExecutionStatus.finished) {
            return { isError: true, errDescription: response.message };
        } else {
            return { isError: false };
        }
    }

    async removeTelephony() {
        const response = await removeTelephonyProvider({
            telephonyProviderId: (
                this.telephonyForRemove as MangoOfficeTelephonyProviderClient
            ).id,
        });
        if (response.executionStatus !== ExecutionStatus.finished) {
            return { isError: true, errDescription: response.message };
        } else {
            return { isError: false };
        }
    }

    async updateOrgBranchManagementCompany(
        address: string,
        description: string,
        location: LocationInterface,
        phone: string,
        url: string,
        telegram: string,
        title: string,
        whatsapp: string,
        email: string,
    ) {
        const res = await updateManagementCompany({
            orgBranchId: this.organizationBranchIdToInfo,
            managementCompany: {
                address: address,
                description: description,
                email: email,
                location: location,
                logo: this.logoFile
                    ? { filename: 'logo', fileId: this.logoFile.fileId }
                    : this.currentOrgBranchInfo.logo
                    ? {
                          filename: this.currentOrgBranchInfo.logo.filename,
                          fileId: this.currentOrgBranchInfo.logo.fileId,
                      }
                    : null,
                phone: phone,
                photo: this.photoFile
                    ? { filename: 'photo', fileId: this.photoFile.fileId }
                    : this.currentOrgBranchInfo.photo
                    ? {
                          filename: this.currentOrgBranchInfo.photo.filename,
                          fileId: this.currentOrgBranchInfo.photo.fileId,
                      }
                    : null,
                siteUrl: url,
                telegram: telegram,
                title: title,
                whatsApp: whatsapp,
            },
        });
        return res === null;
    }

    async getUploadLink(
        params: ObjectStorageGetUploadLinkRequest,
        type: string,
    ) {
        const response = await getUploadLink(params);
        if (type === 'photo') {
            this.setPhotoFile(response);
        } else if (type === 'logo') {
            this.setLogoFile({
                fileId: response.fileId,
                uploadLink: response.uploadLink,
            });
        }
    }

    async getHouses(orgBranchId?: number) {
        const response = await getSystemObjects({
            types: [SystemObjectType.House],
            organizationBranchesIds: orgBranchId ? [orgBranchId] : [],
            onlyFirstLevelChildren: true,
        });
        runInAction(() => {
            const map = {};
            Object.entries(response.systemObjectsByParentId).forEach((arr) => {
                arr[1].forEach((item) => {
                    map[item.id] = item;
                });
            });
            this.systemObjects = response.systemObjectsByParentId;
            this.id2SystemObjectMap = map;
            this.loading = false;
        });
    }

    async loadChildSystemObjects(systemObjectId: number) {
        runInAction(() => {
            this.loadingChildren[systemObjectId] = true;
        });
        const systemObject = this.id2SystemObjectMap[systemObjectId];
        let response: {
            systemObjectsByParentId: { [key: string]: SystemObjectT[] };
        } = {
            systemObjectsByParentId: {},
        };
        if (systemObject.type === SystemObjectType.House) {
            response = await getSystemObjects({
                systemObjectsIds: [systemObjectId],
                onlyFirstLevelChildren: true,
                types: [SystemObjectType.Flat, SystemObjectType.Hub],
            });
        }

        runInAction(() => {
            Object.entries(response.systemObjectsByParentId).forEach((arr) => {
                arr[1].forEach((item) => {
                    this.id2SystemObjectMap[item.id] = item;
                });
                this.systemObjects[arr[0]] = arr[1];
            });
            this.systemObjects[systemObjectId] =
                response.systemObjectsByParentId[systemObjectId];
            this.loading = false;
            this.loadingChildren[systemObjectId] = false;
        });
    }

    async reloadSystemObjectsAfterChildUpdate(
        childId: number,
        orgBranchId: number,
        parentId?: number,
    ) {
        const object = this.id2SystemObjectMap[childId];

        if (object?.parentId) {
            await this.loadChildSystemObjects(object.parentId);
        } else if (parentId) {
            await this.loadChildSystemObjects(parentId);
        } else {
            await this.getHouses(orgBranchId);
        }
    }

    async updateSignUpConfig(
        params: HouseResidentSignUpConfig,
        orgBranchId: number,
    ) {
        const response = await updateHouseSignUpConfig({
            houseId: this.houseIdForSignUpConfig,
            houseResidentSignUpConfig: {
                createUserFirst: params.createUserFirst,
                validationPoints: {
                    phoneNumber: params.validationPoints.phoneNumber,
                    login: params.validationPoints.login,
                    fullName: params.validationPoints.fullName,
                },
            },
        });
        if (response.executionStatus === ExecutionStatus.finished) {
            await this.reloadSystemObjectsAfterChildUpdate(
                this.houseIdForSignUpConfig,
                orgBranchId,
            );
            return { isError: false };
        } else {
            return { isError: true, errDescription: response.message };
        }
    }

    async getEmployeesAccounts() {
        const response = await getAccounts({
            accountRoles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
                AccountRole.Support,
            ],
        });
        this.employeesList = response.accounts;
    }

    @action
    closeSystemObject(systemObjectId: number) {
        delete this.systemObjects[systemObjectId];
    }

    async removeBranch() {
        const response = await removeOrgBranch({
            orgBranchId: this.orgBranchForRemove.id,
        });
        return response === null;
    }

    @action
    openSignUpConfigModal(config: HouseResidentSignUpConfig, id: number) {
        this.signUpConfigModal = true;
        this.currentSignUpConfig = config;
        this.houseIdForSignUpConfig = id;
    }

    @action
    closeSignUpConfigModal() {
        this.currentSignUpConfig = undefined;
        this.houseIdForSignUpConfig = undefined;
        this.signUpConfigModal = false;
    }

    @action
    setLogoFile(file: { fileId: string; uploadLink: string }) {
        this.logoFile = file;
    }

    @action
    setPhotoFile(file: ObjectStorageGetUploadLinkResponse) {
        this.photoFile = file;
    }

    @action
    setCurrentCompanyPhotoLink(link: string) {
        this.cropPhotoForInfoLink = link;
    }

    @action
    setCurrentCompanyLogoLink(link: string) {
        this.cropLogoForInfoLink = link;
    }

    @action
    setCurrent(branch: OrganizationBranch) {
        this.current = branch;
    }

    @action
    openEditProviderModal(houseId: number, provider?: PaymentProviderClient) {
        this.currentHouseId = houseId;
        this.currentProvider = provider;
        this.editProviderModal = true;
    }

    @action
    closeEditProviderModal() {
        this.editProviderModal = false;
        this.currentProvider = undefined;
        this.currentHouseId = undefined;
    }

    @action
    openRemoveProviderForm(provider: PaymentProviderClient) {
        this.providerForRemove = provider;
        this.removeProviderForm = true;
    }

    @action
    closeRemoveProviderForm() {
        this.removeProviderForm = false;
        this.providerForRemove = undefined;
    }

    @action
    openEditTelephonyModal(
        telephony?: TelephonyProviderClient,
        branchId?: number,
    ) {
        this.branchIdForCreateTelephony = branchId;
        this.currentTelephony = telephony;
        this.editTelephonyModal = true;
    }

    @action
    closeEditTelephonyModal() {
        this.editTelephonyModal = false;
        this.currentTelephony = undefined;
        this.branchIdForCreateTelephony = undefined;
    }

    @action
    openRemoveTelephonyForm(telephony: TelephonyProviderClient) {
        this.telephonyForRemove = telephony;
        this.removeTelephonyForm = true;
    }

    @action
    closeRemoveTelephonyForm() {
        this.removeTelephonyForm = false;
        this.telephonyForRemove = undefined;
    }
}

export default OrganizationBranchesStore;

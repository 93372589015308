import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { OrganizationBranch } from '../services/organizations/models';
import { getOrganizationBrunches } from '../services/organizations';
import moment, { Moment } from 'moment';
import { getAllAlerts } from '../services/alerts';
import {
    AlertStatus,
    AlertType,
    SummaryAlert,
} from '../services/alerts/models';

export enum EmergencyAlertType {
    LeakageAlert = 'LeakageAlert',
    FireAlert = 'FireAlert',
    SecurityAlert = 'SecurityAlert',
}

interface IEmergencyAlertDescription {
    type: EmergencyAlertType;
    description: string;
    createdAt: Moment;
}

export enum Mode {
    prod = 'prod',
    demo = 'demo',
}

class GeneralStore {
    pageTitle = '';
    backLink: string;
    titleButtons: JSX.Element[];
    headerButtons: JSX.Element[];
    mode: Mode = Mode.prod;
    snackbar: {
        title: string;
        isOpen: boolean;
    } = {
        title: '',
        isOpen: false,
    };

    organizationBranches: OrganizationBranch[] = [];
    currentOrganizationBranch?: OrganizationBranch;
    emergencySituations: IEmergencyAlertDescription[] = [];
    alertsBySensorId: { [s: string]: SummaryAlert[] } = {};

    constructor() {
        makeAutoObservable(this);
    }

    @computed
    get currentOrgBranchId() {
        return this.currentOrganizationBranch?.id;
    }

    @computed
    isDemo() {
        return this.mode === Mode.demo;
    }

    init() {
        this.loadOrganizationBrunches();
    }

    @action
    setMode(mode: Mode) {
        this.mode = mode;
    }

    async loadOrganizationBrunches() {
        const res = await getOrganizationBrunches();
        runInAction(() => {
            this.organizationBranches = res.organizationBranches;
            if (this.organizationBranches.length) {
                if (localStorage.branch) {
                    const branch = localStorage.getItem('branch');
                    if (
                        this.organizationBranches
                            .map((i) => i.id)
                            .includes(parseInt(JSON.parse(branch)))
                    ) {
                        this.setOrgBranch(parseInt(JSON.parse(branch)));
                    } else {
                        this.currentOrganizationBranch =
                            this.organizationBranches.length > 0
                                ? this.organizationBranches[0]
                                : undefined;
                        localStorage.setItem(
                            'branch',
                            JSON.stringify(this.currentOrganizationBranch.id),
                        );
                    }
                } else {
                    this.currentOrganizationBranch =
                        this.organizationBranches.length > 0
                            ? this.organizationBranches[0]
                            : undefined;
                    localStorage.setItem(
                        'branch',
                        JSON.stringify(this.currentOrganizationBranch.id),
                    );
                }
            }
        });
    }

    @action
    setOrgBranch(currentOrganizationBranchId: number) {
        const currentOrgBranch = this.organizationBranches.find((item) => {
            return item.id === currentOrganizationBranchId;
        });
        this.currentOrganizationBranch = currentOrgBranch;
    }

    @action
    setPageTitle(
        title: string,
        headerButtons: JSX.Element[] = null,
        titleButtons: JSX.Element[] = null,
        backLink?: string,
    ) {
        this.pageTitle = title;
        this.headerButtons = headerButtons;
        this.titleButtons = titleButtons;
        this.backLink = backLink;
    }

    @action
    showSnackbar(title: string) {
        this.snackbar = {
            title: title,
            isOpen: true,
        };
        setTimeout(() => {
            this.closeSnackbar();
        }, 5000);
    }

    @action
    closeSnackbar() {
        this.snackbar = {
            title: '',
            isOpen: false,
        };
    }

    @action
    addEmergencySituation(situation: IEmergencyAlertDescription) {
        this.emergencySituations.push(situation);
    }

    @action
    DEMOActivateEmergencyLeakDetection() {
        this.emergencySituations = [
            {
                type: EmergencyAlertType.LeakageAlert,
                description:
                    'Сработал датчик протечки ЖК Легенда, Ахметзянова 4, Подъезд 1, Этаж 8',
                createdAt: moment().startOf('hour'),
            },
        ];
    }

    async getEmergencySituations() {
        const res = await getAllAlerts({
            statuses: [AlertStatus.active],
            alertTypes: [
                'SecurityAlert',
                'LeakageAlert',
                'FireAlert',
            ] as AlertType[],
            flatIds: [],
            houseIds: [],
            limit: 0,
            measurementTypes: [],
            orgBranchIds: [],
            skip: 0,
        });
        runInAction(() => {
            this.emergencySituations =
                res?.data.alerts.map((a) => ({
                    type: a.type as EmergencyAlertType,
                    description: `${a.parents.house?.name}, ${a.parents.flat?.name}`,
                    createdAt: moment(a.activatedAt),
                })) || [];

            this.alertsBySensorId = res?.data.alerts.reduce((memo, alert) => {
                memo[alert.systemObject.id] = memo[alert.systemObject.id] || [];
                memo[alert.systemObject.id].push(alert);
                return memo;
            }, {});
        });
    }
}

export default GeneralStore;

import axios, { AxiosResponse } from 'axios';
import { getToken } from '../../apiCommandsExecutor/token';
import { MeasurementType } from '../alerts/models';
import { ParseInfo } from '../systemObjects/models';
import { API_URL } from '../../apiCommandsExecutor';
import { SensorModelName } from '../systemObjects/types';
import { DomilendResponse, DomilendResult } from '../flatOwners/models';

export const importHouseFile = async (file: File, houseId: number) => {
    const formData = new FormData();
    formData.append('file', file);

    const res: AxiosResponse<ParseInfo[]> = await axios.post(
        `${API_URL}/api/upload/system-objects/table-house/${houseId}.xls`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                AuthToken: getToken(),
                Authorization: `Bearer ${getToken()}`,
            },
        },
    );
    return res;
};

export const importReceiptFile = async (file: File, houseId: number) => {
    const formData = new FormData();
    formData.append('file', file);

    const res: AxiosResponse<ParseInfo[]> = await axios.post(
        `${API_URL}/api/upload/flat-receipts/${houseId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                AuthToken: getToken(),
                Authorization: `Bearer ${getToken()}`,
            },
        },
    );
    return res;
};

export const importDomilendReceiptFile = async (
    file: File,
    houseId: number,
) => {
    const formData = new FormData();
    formData.append('file', file);

    const res: AxiosResponse<DomilendResponse> = await axios.post(
        `${API_URL}/api/upload/domilend-flat-receipts/${houseId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                AuthToken: getToken(),
                Authorization: `Bearer ${getToken()}`,
            },
        },
    );
    return res;
};

export const importResidentFile = async (file: File, houseId: number) => {
    const formData = new FormData();
    formData.append('file', file);

    const res: AxiosResponse<ParseInfo[]> = await axios.post(
        `${API_URL}/api/upload/houseResidents/${houseId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                AuthToken: getToken(),
                Authorization: `Bearer ${getToken()}`,
            },
        },
    );
    return res;
};

export const importFlatFile = async (file: File, houseId: number) => {
    const formData = new FormData();
    formData.append('file', file);

    const res: AxiosResponse<ParseInfo[]> = await axios.post(
        `${API_URL}/api/upload/table-flats/${houseId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                AuthToken: getToken(),
                Authorization: `Bearer ${getToken()}`,
            },
        },
    );
    return res;
};

export const importPatterForAddSensorsFile = async (
    file: File,
    houseId: number,
    measurementType: MeasurementType,
    sensorModel: SensorModelName,
) => {
    const formData = new FormData();
    formData.append('file', file);

    const res: AxiosResponse<ParseInfo[]> = await axios.post(
        `${API_URL}/api/upload/table-flats/amount-sensors-to-create/${houseId}?measurementType=${measurementType}&sensorModel=${sensorModel}`,
        formData,
        {
            headers: {
                AuthToken: getToken(),
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${getToken()}`,
            },
        },
    );
    return res;
};

export const importMeasurements = async (
    file: File,
    date: Date,
    houseId: number,
) => {
    const formData = new FormData();
    formData.append('file', file);

    const res: AxiosResponse<DomilendResult> = await axios.post(
        `${API_URL}/api/upload/domilend-sensors-measurements?date=${date.toISOString()}&houseId=${houseId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                AuthToken: getToken(),
                Authorization: `Bearer ${getToken()}`,
            },
        },
    );
    return res;
};

export const importSipGroups = async (file: File, sipServedId: number) => {
    const formData = new FormData();
    formData.append('file', file);

    const res: AxiosResponse<ParseInfo[]> = await axios.post(
        `${API_URL}/api/upload/flat-sipgroups?sipServerId=${sipServedId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                AuthToken: getToken(),
                Authorization: `Bearer ${getToken()}`,
            },
        },
    );
    return res;
};

export type FileType =
    | 'jpg'
    | 'jpeg'
    | 'heic'
    | 'png'
    | 'svg'
    | 'jfif'
    | 'gif'
    | 'mp4'
    | 'webm'
    | 'mov'
    | 'xlsx'
    | 'xls'
    | 'docx'
    | 'doc'
    | 'pdf'
    | 'webp'
    | 'ppt'
    | 'pptx';

export const FileTypes: { [key in FileType]?: string } = {
    jpg: 'image',
    jpeg: 'image',
    heic: 'image',
    png: 'image',
    svg: 'image',
    jfif: 'image',
    gif: 'image',
    webp: 'image',
    mp4: 'video',
    webm: 'video',
    mov: 'video',
    xlsx: 'excel',
    xls: 'excel',
    docx: 'word',
    doc: 'word',
    pdf: 'pdf',
    ppt: 'pp',
    pptx: 'pp',
};

import {
    TelephonyProviderCreateMangoOfficeTelephonyProviderRequest,
    TelephonyProviderCreateMangoOfficeTelephonyProviderResponse,
    TelephonyProviderGetTelephonyProvidersRequest,
    TelephonyProviderGetTelephonyProvidersResponse,
    TelephonyProviderRemoveRequest,
    TelephonyProviderRemoveResponse,
    TelephonyProviderUpdateMangoOfficeTelephonyProviderRequest,
    TelephonyProviderUpdateMangoOfficeTelephonyProviderResponse,
} from './requestsResponses';
import { execute } from '../../apiCommandsExecutor';
import { TelephonyApi } from './methods';

export const createTelephonyProvider = async (
    params: TelephonyProviderCreateMangoOfficeTelephonyProviderRequest,
) => {
    const res = await execute<
        TelephonyProviderCreateMangoOfficeTelephonyProviderRequest,
        TelephonyProviderCreateMangoOfficeTelephonyProviderResponse
    >(TelephonyApi.TelephonyProviderCreateMangoOfficeTelephonyProvider, {
        ...params,
    });
    return res.data;
};

export const updateTelephonyProvider = async (
    params: TelephonyProviderUpdateMangoOfficeTelephonyProviderRequest,
) => {
    const res = await execute<
        TelephonyProviderUpdateMangoOfficeTelephonyProviderRequest,
        TelephonyProviderUpdateMangoOfficeTelephonyProviderResponse
    >(TelephonyApi.TelephonyProviderUpdateMangoOfficeTelephonyProvider, {
        ...params,
    });
    return res.data;
};

export const getTelephonyProviders = async (
    params: TelephonyProviderGetTelephonyProvidersRequest,
) => {
    const res = await execute<
        TelephonyProviderGetTelephonyProvidersRequest,
        TelephonyProviderGetTelephonyProvidersResponse
    >(TelephonyApi.TelephonyProviderGetTelephonyProviders, { ...params });
    return res.data;
};

export const removeTelephonyProvider = async (
    params: TelephonyProviderRemoveRequest,
) => {
    const res = await execute<
        TelephonyProviderRemoveRequest,
        TelephonyProviderRemoveResponse
    >(TelephonyApi.TelephonyProviderRemove, { ...params });
    return res.data;
};

import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import {
    ConnectionLineModel,
    HubModel,
    SensorMeasurementKind,
    SensorModel,
} from '../services/constantsRegistry/models';
import {
    getConnectionLineModels,
    getHubModels,
    getMeasurementKinds,
    getSensorModels,
} from '../services/constantsRegistry';
import {
    addMeasurements,
    createConnectionLine,
    createCopy,
    createFlat,
    createHouse,
    createHub,
    createSensor,
    createSensorPlaceholder,
    generateHouseTemplate,
    getSystemObjectById,
    getSystemObjects,
    moveHouse,
    updateConnectionLine,
    updateExternalId,
    updateFlat,
    updateHouse,
    updateHub,
    updateManualSendingNotifications,
    updateSensor,
    updateSensorPlaceholder,
} from '../services/systemObjects';
import { getSummary } from '../services/alerts';
import { SummaryData } from '../services/alerts/models';
import moment from 'moment';
import { ArchiveType } from '../services/flatView/models';
import {
    SystemObjectClient,
    SystemObjectFlat,
    SystemObjectHouse,
    SystemObjectHub,
    SystemObjectT,
    SystemObjectType,
} from '../services/systemObjects/types';
import {
    ConnectionLineData,
    FlatData,
    FlatSensorDescriptor,
    HouseData,
    HubData,
    ParseInfo,
    SensorData,
    SensorMeasurementData,
    SensorPlaceholderData,
} from '../services/systemObjects/models';
import { removeSensorMeasurements } from '../services/sensorMeasurements';
import { ExecutionStatus } from '../apiCommandsExecutor/api';

const formByType = {
    [SystemObjectType.House]: SystemObjectType.Flat,
    [SystemObjectType.Flat]: SystemObjectType.SensorPlaceholder,
    [SystemObjectType.SensorPlaceholder]: SystemObjectType.Sensor,
    [SystemObjectType.Hub]: SystemObjectType.ConnectionLine,
};

export const ImportHouseFile = 'ImportHouseFile';
export const DeleteSystemObjectId = 'DeleteSystemObjectId';
export const HouseTemplateGeneratorForm = 'HouseTemplateGenerator';
export const MoveHouse = 'MoveHouse';
export const ExcelErrors = 'ExcelErrors';
export const ImportExcelFile = 'ImportExcelFile';
export const DuplicateSystemObjectId = 'DuplicateSystemObjectId';

export enum ImportFile {
    resident = 'Импорт жителей',
    receipt = 'Импорт квитанций',
    flat = 'Импорт квартир',
    patternForAddSensors = 'Импорт шаблона для массового добавления устройств',
    measurements = 'Импорт показаний',
    sipGroups = 'Импорт групп и квартир',
}

class SystemObjectsStore {
    loadingChildren: {
        [s: string]: boolean;
    } = {};
    loading = true;
    systemObjects: {
        [s: string]: SystemObjectT[] | null;
    } = {};
    id2SystemObjectMap: { [s: string]: SystemObjectT } = {};
    current?: SystemObjectT;
    currentHouseId?: number;
    parent?: SystemObjectT;
    formsState: {
        [SystemObjectType.House]: boolean;
        [SystemObjectType.Flat]: boolean;
        [SystemObjectType.SensorPlaceholder]: boolean;
        [SystemObjectType.Sensor]: boolean;
        [SystemObjectType.Hub]: boolean;
        [SystemObjectType.ConnectionLine]: boolean;
        [ImportHouseFile]: boolean;
        [ImportExcelFile]: boolean;
        [HouseTemplateGeneratorForm]: boolean;
        [DeleteSystemObjectId]: boolean;
        [MoveHouse]: boolean;
        [ExcelErrors]: boolean;
        [DuplicateSystemObjectId]: boolean;
    } = {
        [SystemObjectType.House]: false,
        [SystemObjectType.Flat]: false,
        [SystemObjectType.SensorPlaceholder]: false,
        [SystemObjectType.Sensor]: false,
        [SystemObjectType.Hub]: false,
        [SystemObjectType.ConnectionLine]: false,
        [ImportHouseFile]: false,
        [ImportExcelFile]: false,
        [HouseTemplateGeneratorForm]: false,
        [DeleteSystemObjectId]: false,
        [MoveHouse]: false,
        [ExcelErrors]: false,
        [DuplicateSystemObjectId]: false,
    };

    sensorMeasurementKinds: SensorMeasurementKind[] = [];
    sensorModelsByMeasurementKind: { [key: string]: SensorModel[] } = {};
    hubModels: HubModel[] = [];
    connectionLineModels: ConnectionLineModel[] = [];
    organizationBranchId?: number;

    stats: SummaryData = {
        amountConnectionLines: 0,
        amountFlats: 0,
        amountHouses: 0,
        amountHubs: 0,
        amountOrgBranches: 0,
        amountSensors: 0,
    };

    excelErrors: ParseInfo[] = [];
    importFileModalState: ImportFile;

    removeMeasurementsModal = false;
    fromDate = moment().startOf('month').toDate();
    toDate = moment().toDate();
    archiveTypes: ArchiveType[] = [];
    sensorId: number;
    houseIds: number[] = [];
    additionalSettingForm = false;
    editSensorStatusModal = false;
    idForEditSensorStatusModal: number;
    sensorDataForEditSensorModal: SystemObjectClient;
    addMeasurementsModal = false;
    dateForMeasurements: Date = moment().toDate();
    externalIdentForm = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    async init() {
        const sensorMeasurementKindsResponse = await getMeasurementKinds();
        const sensorModelsByMeasurementKindId = await getSensorModels();
        const { hubModels } = await getHubModels();
        const { connectionLineModels } = await getConnectionLineModels();
        runInAction(() => {
            this.sensorMeasurementKinds =
                sensorMeasurementKindsResponse.sensorMeasurementKinds;
            this.sensorModelsByMeasurementKind =
                sensorModelsByMeasurementKindId.sensorModelsByMeasurementKindId;
            this.hubModels = hubModels;
            this.connectionLineModels = connectionLineModels;
            this.loading = false;
        });
    }

    async getSystemObjectsByOrgBranchIds(organizationBranchId: number) {
        this.organizationBranchId = organizationBranchId;
        await this.getSystemObjects(true);
    }

    async getSystemObjects(onlyHouses = false) {
        const response = await getSystemObjects({
            types: onlyHouses ? [SystemObjectType.House] : undefined,
            organizationBranchesIds: this.organizationBranchId
                ? [this.organizationBranchId]
                : [],
        });
        runInAction(() => {
            const map = {};
            Object.entries(response.systemObjectsByParentId).forEach((arr) => {
                arr[1].forEach((item) => {
                    map[item.id] = item;
                });
            });
            this.systemObjects = response.systemObjectsByParentId;
            this.id2SystemObjectMap = map;
            this.houseIds = Object.keys(this.id2SystemObjectMap).map((i) =>
                parseInt(i),
            );
            this.loading = false;
        });
    }

    async loadChildSystemObjects(systemObjectId: number) {
        runInAction(() => {
            this.loadingChildren[systemObjectId] = true;
        });
        const systemObject = this.id2SystemObjectMap[systemObjectId];
        let response: {
            systemObjectsByParentId: { [key: string]: SystemObjectT[] };
        } = {
            systemObjectsByParentId: {},
        };
        let lines: {
            systemObjectsByParentId: { [key: string]: SystemObjectT[] };
        } = {
            systemObjectsByParentId: {},
        };
        if (systemObject.type === SystemObjectType.House) {
            response = await getSystemObjects({
                systemObjectsIds: [systemObjectId],
                onlyFirstLevelChildren: true,
                types: [SystemObjectType.Flat, SystemObjectType.Hub],
            });
            const values = Object.values(response.systemObjectsByParentId);
            const hubsIds = values[0]
                .filter((item) => item && item.type === SystemObjectType.Hub)
                .map((i) => i.id);

            if (hubsIds.length > 0) {
                lines = await getSystemObjects({
                    systemObjectsIds: hubsIds,
                    onlyFirstLevelChildren: true,
                });
                response.systemObjectsByParentId = Object.assign(
                    response.systemObjectsByParentId,
                    lines.systemObjectsByParentId,
                );
            }
        } else if (systemObject.type === SystemObjectType.Flat) {
            response = await getSystemObjects({
                systemObjectsIds: [systemObjectId],
                types: [
                    SystemObjectType.SensorPlaceholder,
                    SystemObjectType.Hub,
                    SystemObjectType.ConnectionLine,
                ],
            });
        } else {
            response = await getSystemObjects({
                systemObjectsIds: [systemObjectId],
                onlyFirstLevelChildren: true,
            });
        }

        runInAction(() => {
            Object.entries(response.systemObjectsByParentId).forEach((arr) => {
                /*setTimeout(() => {
                    arr[1].forEach(item => {
                        this.id2SystemObjectMap[item.id] = item;
                    })
                }, 1);*/
                arr[1].forEach((item) => {
                    this.id2SystemObjectMap[item.id] = item;
                });
                this.systemObjects[arr[0]] = arr[1];
            });
            this.systemObjects[systemObjectId] =
                response.systemObjectsByParentId[systemObjectId];
            this.loading = false;
            this.loadingChildren[systemObjectId] = false;
        });
    }

    async updateManualSendingNotificationsForHouse(
        status: boolean,
        houseId: number,
    ) {
        const response = await updateManualSendingNotifications({
            manualSendingNotificationsEnabled: status,
            houseId: houseId,
        });
        return response === null;
    }

    @action
    resetData() {
        this.organizationBranchId = undefined;
        this.systemObjects = {};
    }

    @action
    closeSystemObject(systemObjectId: number) {
        delete this.systemObjects[systemObjectId];
    }

    @computed
    getHubSystemObjects(objId: number) {
        let systemObj = this.id2SystemObjectMap[objId];
        let hubs: SystemObjectHub[] = [];

        while (
            systemObj.type !== SystemObjectType.House &&
            systemObj.type !== SystemObjectType.Flat
        ) {
            systemObj = this.id2SystemObjectMap[systemObj.parentId];
        }
        hubs = (this.systemObjects[systemObj.id] || []).filter(
            (item) => item.type === SystemObjectType.Hub,
        ) as SystemObjectHub[];
        if (systemObj.type === SystemObjectType.Flat) {
            systemObj = this.id2SystemObjectMap[systemObj.parentId];
            return [
                ...hubs,
                ...(this.systemObjects[systemObj.id] || []).filter(
                    (item) => item.type === SystemObjectType.Hub,
                ),
            ] as SystemObjectHub[];
        } else {
            return hubs;
        }
    }

    @computed
    getLineObjectsByHubId(hubId: number) {
        return this.systemObjects[hubId] || [];
    }

    async reloadSystemObjectsAfterChildUpdate(
        childId: number,
        parentId?: number,
    ) {
        const object = this.id2SystemObjectMap[childId];

        if (object?.parentId) {
            await this.loadChildSystemObjects(object.parentId);
        } else if (parentId) {
            await this.loadChildSystemObjects(parentId);
        } else {
            await this.getSystemObjects(true);
        }
    }

    @action
    async createHouse(params: {
        data: HouseData;
        parentId: number | null;
        orgBranchId: number;
    }): Promise<boolean> {
        const response = await createHouse(params);
        await this.reloadSystemObjectsAfterChildUpdate(
            response.systemObjectId,
            params.parentId,
        );
        return false;
    }

    @action
    async editHouse(id: number, params: { data: HouseData }): Promise<boolean> {
        const res = await updateHouse({ id, ...params });
        await this.reloadSystemObjectsAfterChildUpdate(id);
        return res === null;
    }

    @action
    async createFlat(params: {
        data: FlatData;
        parentId: number | null;
    }): Promise<boolean> {
        const response = await createFlat(params);
        await this.reloadSystemObjectsAfterChildUpdate(
            response.systemObjectId,
            params.parentId,
        );
        return false;
    }

    @action
    async editFlat(id: number, params: { data: FlatData }): Promise<boolean> {
        const res = await updateFlat({ id, ...params });
        await this.reloadSystemObjectsAfterChildUpdate(id);
        return res === null;
    }

    @action
    async createSensorPlaceholder(params: {
        data: SensorPlaceholderData;
        parentId: number | null;
    }): Promise<boolean> {
        const response = await createSensorPlaceholder(params);
        await this.reloadSystemObjectsAfterChildUpdate(
            response.systemObjectId,
            params.parentId,
        );
        return false;
    }

    @action
    async editSensorPlaceholder(
        id: number,
        params: { data: SensorPlaceholderData },
    ): Promise<boolean> {
        const res = await updateSensorPlaceholder({ id, ...params });
        await this.reloadSystemObjectsAfterChildUpdate(id);
        return res === null;
    }

    @action
    async createSensor(params: {
        data: SensorData;
        parentId: number | null;
    }): Promise<boolean> {
        const response = await createSensor(params);
        if (response === null) {
            return true;
        }
        await this.reloadSystemObjectsAfterChildUpdate(
            response.systemObjectId,
            params.parentId,
        );
        return false;
    }

    @action
    async editSensor(
        id: number,
        params: { data: SensorData },
    ): Promise<boolean> {
        const res = await updateSensor({ id, ...params });
        await this.reloadSystemObjectsAfterChildUpdate(id);
        return res === null;
    }

    @action
    async createHub(params: {
        data: HubData;
        parentId: number | null;
    }): Promise<boolean> {
        const response = await createHub(params);
        await this.reloadSystemObjectsAfterChildUpdate(
            response.systemObjectId,
            params.parentId,
        );
        return false;
    }

    @action
    async editHub(id: number, params: { data: HubData }): Promise<boolean> {
        const res = await updateHub({ id, ...params });
        await this.reloadSystemObjectsAfterChildUpdate(id);
        return res === null;
    }

    @action
    async moveHouse(houseId: number, newOrgBranchId: number) {
        await moveHouse({ houseId, newOrgBranchId });
        await this.reloadSystemObjectsAfterChildUpdate(houseId);
    }

    @action
    async createConnectionLine(params: {
        data: ConnectionLineData;
        parentId: number | null;
    }): Promise<boolean> {
        const response = await createConnectionLine(params);
        await this.reloadSystemObjectsAfterChildUpdate(
            response.systemObjectId,
            params.parentId,
        );
        return false;
    }

    @action
    async editConnectionLine(
        id: number,
        params: { data: ConnectionLineData },
    ): Promise<boolean> {
        const res = await updateConnectionLine({ id, ...params });
        await this.reloadSystemObjectsAfterChildUpdate(id);
        return res === null;
    }

    async updateAdditionalSettings(additionalSettings: { [key: string]: any }) {
        if (this.current.type === SystemObjectType.Sensor) {
            const response = await updateSensor({
                id: this.current.id,
                data: {
                    ...this.current.data,
                    additionalSettings: additionalSettings,
                },
            });
            await this.reloadSystemObjectsAfterChildUpdate(this.current.id);
            return response === null;
        }
    }

    @action
    openAdditionalSettingsForm(obj: SystemObjectT) {
        this.setCurrent(obj);
        this.additionalSettingForm = true;
    }

    @action
    closeAdditionalSettingsForm() {
        this.additionalSettingForm = false;
    }

    @action
    openExternalIdentForm(obj: SystemObjectT) {
        this.setCurrent(obj);
        this.externalIdentForm = true;
    }

    @action
    closeExternalIdentForm() {
        this.externalIdentForm = false;
    }

    @action
    openFormByParent(type: SystemObjectType) {
        this.formsState[formByType[type]] = true;
    }

    @action
    openForm(type: SystemObjectType) {
        this.formsState[type] = true;
    }

    @action
    closeForm(type: SystemObjectType) {
        this.formsState[type] = false;
    }

    @action
    setCurrent(t: SystemObjectT) {
        this.current = t;
    }

    @action
    setParent(t?: SystemObjectT) {
        this.parent = t;
    }

    async removeSensorMeasurements() {
        const res = await removeSensorMeasurements({
            fromDate: this.fromDate,
            toDate: this.toDate,
            sensorId: this.sensorId,
            measurementSourceIds: this.archiveTypes,
        });
        return res === null;
    }

    @action
    openRemoveMeasurementsModal(id: number) {
        this.sensorId = id;
        this.removeMeasurementsModal = true;
    }

    @action
    closeRemoveMeasurementsModal() {
        this.removeMeasurementsModal = false;
        this.sensorId = null;
        this.archiveTypes = [];
        this.fromDate = moment().startOf('month').toDate();
        this.toDate = moment().toDate();
    }

    @action
    checkArchiveType(type: ArchiveType) {
        return this.archiveTypes.includes(type);
    }

    @action
    toggleArchiveType(type: ArchiveType) {
        if (this.archiveTypes.includes(type)) {
            this.archiveTypes = this.archiveTypes.filter(
                (item) => item !== type,
            );
        } else {
            this.archiveTypes.push(type);
        }
    }

    @action
    setFrom(from: Date) {
        this.fromDate = from;
    }

    @action
    setTo(to: Date) {
        this.toDate = to;
    }

    async loadStatistics() {
        const res = await getSummary({});
        runInAction(() => {
            this.stats = res?.data || {
                amountConnectionLines: 0,
                amountFlats: 0,
                amountHouses: 0,
                amountHubs: 0,
                amountOrgBranches: 0,
                amountSensors: 0,
            };
        });
    }

    @computed
    getNoConnectionLineAlerts() {
        return Object.values(this.id2SystemObjectMap).filter((item) => {
            return (
                item.type === SystemObjectType.Sensor &&
                !item.data.connectionLineSystemObjectId
            );
        });
    }

    @computed
    getPathToVeryTop(objId: number) {
        let systemObj = this.id2SystemObjectMap[objId];
        const res = [];
        if (systemObj.type === SystemObjectType.Sensor) {
            res.push(
                `${systemObj.data.name} (${systemObj.data.sensorAddress})`,
            );
        } else {
            res.push(systemObj.data.name);
        }

        while (systemObj.parentId !== null) {
            systemObj = this.id2SystemObjectMap[systemObj.parentId];
            res.push(systemObj.data.name);
        }
        return res.reverse();
    }

    @computed
    hasPath(objId: number) {
        let systemObj = this.id2SystemObjectMap[objId];
        const res = [objId];
        if (!systemObj) {
            console.log(res);
            return false;
        }
        while (systemObj.parentId !== null) {
            systemObj = this.id2SystemObjectMap[systemObj.parentId];
            if (systemObj === undefined) {
                return false;
            }
            res.push(systemObj.parentId);
        }
        return true;
    }

    @action
    openImportHouseFileForm = (house: SystemObjectHouse) => {
        this.formsState[ImportHouseFile] = true;
        this.setCurrent(house);
    };

    @action
    closeImportHouseFileForm = () => {
        this.formsState[ImportHouseFile] = false;
        this.setCurrent(undefined);
    };

    @action
    openImportExcelFileForm = () => {
        this.formsState[ImportExcelFile] = true;
    };

    @action
    closeImportExcelFileForm = () => {
        this.formsState[ImportExcelFile] = false;
    };

    @action
    changeCurrentImportModalState = (type: ImportFile) => {
        this.importFileModalState = type;
    };

    @action
    openMoveHouseForm = (house: SystemObjectHouse) => {
        this.formsState[MoveHouse] = true;
        this.setCurrent(house);
    };

    @action
    closeMoveHouseForm = () => {
        this.formsState[MoveHouse] = false;
        this.setCurrent(undefined);
    };

    @action
    openDeleteSystemObjectIdForm = (house: SystemObjectT) => {
        this.formsState[DeleteSystemObjectId] = true;
        this.setCurrent(house);
    };

    @action
    closeDeleteSystemObjectIdForm = () => {
        this.formsState[DeleteSystemObjectId] = false;
        this.setCurrent(undefined);
    };

    @action
    openDuplicateSystemObjectIdForm = (obj: SystemObjectT) => {
        this.formsState[DuplicateSystemObjectId] = true;
        this.setCurrent(obj);
    };

    @action
    closeDuplicateSystemObjectIdForm = () => {
        this.formsState[DuplicateSystemObjectId] = false;
        this.setCurrent(undefined);
    };

    @action
    openHouseTemplateGeneratorForm = () => {
        this.formsState[HouseTemplateGeneratorForm] = true;
    };
    @action
    closeHouseTemplateGeneratorForm = () => {
        this.formsState[HouseTemplateGeneratorForm] = false;
    };

    generateHouseTemplate = async (params: {
        name: string;
        flatSensors: FlatSensorDescriptor[];
        numberOfFlats: number;
        firstFlatNumber: number;
        orgBranchId: number;
    }) => {
        const response = await generateHouseTemplate(params);
        if (response) await this.getSystemObjects();

        return response;
    };

    @computed
    getSystemsObjectsByParentId(id: number) {
        return this.systemObjects[id] || [];
    }

    @action
    openExcelErrorsForm(errors: ParseInfo[]) {
        this.formsState[ExcelErrors] = true;
        this.excelErrors = errors;
    }

    @action
    closeExcelErrorsForm() {
        this.formsState[ExcelErrors] = false;
        this.excelErrors = [];
    }

    @computed
    getLocalSystemObjectByType(type: SystemObjectType) {
        return Object.values(this.systemObjects)
            .flat()
            .filter((item) => item.type === type);
    }

    @action
    openEditSensorStatusForm(id: number) {
        this.idForEditSensorStatusModal = id;
        this.editSensorStatusModal = true;
    }

    @action
    closeEditSensorStatusForm() {
        this.idForEditSensorStatusModal = undefined;
        this.editSensorStatusModal = false;
    }

    async getSensorDataForSensorStatusModal() {
        const response = await getSystemObjectById({
            systemObjectId: this.idForEditSensorStatusModal,
        });
        this.sensorDataForEditSensorModal = response.systemObject;
    }

    async createCopy(id: number) {
        const response = await createCopy({
            systemObjectId: id,
        });
        return response !== null;
    }

    @computed
    getRoomNameBySensorPlaceholderId(
        sensorPlaceholderId: number,
        flatId: number,
    ) {
        const flat = this.id2SystemObjectMap[flatId] as SystemObjectFlat;
        const rooms = flat.data.rooms;
        const room = rooms.find((room) => {
            return (
                room.sensorPlaceholders.filter(
                    (t) => t.id === sensorPlaceholderId,
                ).length > 0
            );
        });
        return room;
    }

    @action
    openAddMeasurementsModal(obj: SystemObjectT) {
        this.setCurrent(obj);
        this.addMeasurementsModal = true;
    }

    @action
    closeAddMeasurementsModal() {
        this.addMeasurementsModal = false;
        this.setDateToMeasurements(moment().toDate());
    }

    @action
    setDateToMeasurements(date: Date) {
        this.dateForMeasurements = date;
    }

    async addMeasurementsToSensor(data: SensorMeasurementData | any) {
        const response = await addMeasurements({
            measurement: {
                arrivedToHubAt: this.dateForMeasurements,
                arrivedToBackendAt: this.dateForMeasurements,
                measuredAt: this.dateForMeasurements,
                data: data,
            },
        });
        if (response.executionStatus === ExecutionStatus.finished) {
            return { isError: false };
        } else {
            return { isError: true, errDescription: response.message };
        }
    }

    async updateExternalIdForHouse(id: string) {
        const response = await updateExternalId({
            newExternalIdent: id,
            sysObjectId: this.current.id,
        });
        if (response.executionStatus === ExecutionStatus.finished) {
            await this.reloadSystemObjectsAfterChildUpdate(this.current.id);
            return { isError: false };
        } else {
            return { isError: true, errDescription: response.message };
        }
    }
}

export default SystemObjectsStore;
